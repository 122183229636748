import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class ScreenSizeService {
	private readonly breakpointObserver = inject(BreakpointObserver);

	isSmallScreen(): boolean {
		return this.breakpointObserver.isMatched(Breakpoints.XSmall);
	}

	observeSmallScreen(): Observable<boolean> {
		return this.observeBreakpoint([Breakpoints.XSmall, Breakpoints.HandsetLandscape]);
	}

	observeMediumScreen(): Observable<boolean> {
		return this.observeBreakpoint(Breakpoints.Medium);
	}

	observeLargeScreen(): Observable<boolean> {
		return this.observeBreakpoint(Breakpoints.Large);
	}

	private observeBreakpoint(breakpoints: string | string[]): Observable<boolean> {
		return this.breakpointObserver.observe(breakpoints).pipe(map((state: BreakpointState) => state.matches));
	}
}
