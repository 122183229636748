import { Pipe, PipeTransform } from "@angular/core";
import { PreciseDate } from "../precise-date.type";

@Pipe({
	name: "preciseDate",
})
export class PreciseDatePipe implements PipeTransform {
	transform(value?: PreciseDate | Date | string | number | null, template?: string): string | null {
		if (value instanceof PreciseDate) return value.format(template);

		return new PreciseDate(value).format(template);
	}
}
