import { Injectable, inject } from "@angular/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Observable } from "rxjs";

export enum CacheStorageServiceStoreMode {
	LocalStorage,
	SessionStorage,
}

@Injectable({
	providedIn: "root",
})
export class CacheStorageService {
	private readonly _localStorageService = inject(LocalStorageService);
	private readonly _sessionStorageService = inject(SessionStorageService);

	hasKey(key: string): boolean {
		return this._localStorageService.retrieve(key) !== null;
	}

	saveCacheValue(
		key: string,
		value: any,
		store: CacheStorageServiceStoreMode = CacheStorageServiceStoreMode.LocalStorage
	): void {
		switch (store) {
			case CacheStorageServiceStoreMode.LocalStorage:
				this._localStorageService.store(key, value);
				return;
			case CacheStorageServiceStoreMode.SessionStorage:
				this._sessionStorageService.store(key, value);
				return;
		}
	}

	saveCache<TItem>(
		key: string,
		value: TItem,
		store: CacheStorageServiceStoreMode = CacheStorageServiceStoreMode.LocalStorage
	): void {
		this.saveCacheValue(key, value, store);
	}

	retrieveCacheValue<T>(key: string): T {
		let value = this._localStorageService.retrieve(key);
		if (!value) value = this._sessionStorageService.retrieve(key);
		return value;
	}

	retrieveCacheAsBoolean(key: string): boolean {
		const value = this.retrieveCacheValue<boolean>(key);
		if (!value) return false;
		return value;
	}

	removeCacheValue(key: string): void {
		this._localStorageService.clear(key);
		this._sessionStorageService.clear(key);
	}

	clearAll(): void {
		this._localStorageService.clear();
		this._sessionStorageService.clear();
	}

	clearSessionStorage(): void {
		this._sessionStorageService.clear();
	}

	clearAllExceptKey(key: string) {
		this.clearCachesExceptKeyByStore(key, this._localStorageService);
		this.clearCachesExceptKeyByStore(key, this._sessionStorageService);
	}

	private clearCachesExceptKeyByStore(
		key: string,
		storageService: LocalStorageService | SessionStorageService
	): void {
		const exceptedKey = storageService.retrieve(key);
		storageService.clear();
		storageService.store(key, exceptedKey);
	}

	getObservableCacheItem<T>(key: string): Observable<T> {
		if (this._localStorageService.retrieve(key)) return this._localStorageService.observe(key);

		return this._sessionStorageService.observe(key);
	}
}
