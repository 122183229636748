import dayjs, { OpUnitType } from "dayjs";
import { Duration, DurationUnitType } from "dayjs/plugin/duration";

export class PreciseDate {
	insideDate: dayjs.Dayjs;

	constructor(date?: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string) {
		this.insideDate = dayjs(date, format, locale);
	}

	add = (value: number, unit?: dayjs.ManipulateType): PreciseDate =>
		new PreciseDate(this.insideDate.add(value, unit));
	compare = (dateToCompare: PreciseDate, unit?: DurationUnitType): number =>
		this.internalCompare(dateToCompare, unit);
	createDate = (year: number, month: number, date: number): PreciseDate => this.setDate(year, month, date);
	date = (): number => this.insideDate.date();

	day = (): number => this.insideDate.day();
	daysInMonth = (): number => this.insideDate.daysInMonth();
	duration = (time: number, unit?: DurationUnitType): Duration => dayjs.duration(time, unit);
	format = (template?: string): string => this.insideDate.format(template);
	isValid = (): boolean => this.insideDate.isValid();
	locale = (preset: string | ILocale): PreciseDate => new PreciseDate(this.insideDate.locale(preset));
	month = (): number => this.insideDate.month();
	subtract = (value: number, unit?: dayjs.ManipulateType): PreciseDate =>
		new PreciseDate(this.insideDate.subtract(value, unit));
	toDateOnlyString = (): string => this.insideDate.format("YYYY-MM-DD");
	toISOString = (): string => this.insideDate.toISOString();
	toString = (): string => this.insideDate.toString();
	unix = (): number => this.insideDate.unix();
	valueOf = (): number => this.insideDate.valueOf();
	year = (): number => this.insideDate.year();
	yearName = (): string => this.insideDate.format("YYYY");

	static current = (): PreciseDate => new PreciseDate();
	static dateNames = (): string[] => this.range(31, (i) => new PreciseDate().createDate(2017, 0, i + 1).format("D"));
	static firstDayOfWeek = (): number => dayjs.localeData().firstDayOfWeek();
	static localeDate = (): dayjs.InstanceLocaleDataReturn => dayjs.localeData();
	static locale = (): string => dayjs.locale();

	static longDateFormat = (format: string): string => dayjs.localeData().longDateFormat(format);
	static longMonthNames = (): string[] => dayjs.localeData().months();
	static longWeekdayNames = (): string[] => this.range(7, (i) => dayjs().set("day", i).format("dddd"));
	static narrowWeekdayNames = (): string[] => dayjs.localeData().weekdaysMin();
	static shortMonthNames = (): string[] => dayjs.localeData().monthsShort();
	static shortWeekdayNames = (): string[] => dayjs.localeData().weekdaysShort();

	private internalCompare(dateToCompare: PreciseDate, unit: OpUnitType = "millisecond"): number {
		if (this.insideDate.isSame(dateToCompare.insideDate, unit)) return 0;
		if (this.insideDate.isBefore(dateToCompare.insideDate, unit)) return -1;
		return 1;
	}

	private setDate(year: number, month: number, date: number): PreciseDate {
		return new PreciseDate(this.insideDate.set("year", year).set("month", month).set("date", date));
	}

	private static range<T>(length: number, valueFunction: (index: number) => T): T[] {
		const valuesArray = Array(length);
		for (let i = 0; i < length; i++) {
			valuesArray[i] = valueFunction(i);
		}
		return valuesArray;
	}
}
