import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "stringCapitalize",
})
export class StringCapitalizePipe implements PipeTransform {
	transform(value: string, capitalizationMethod: "sentenceCase"): string {
		if (capitalizationMethod === "sentenceCase") {
			return value.replace(/(^\w{1}|\.\s+\w{1})/g, (match) => match.toUpperCase());
		}

		return "";
	}
}
