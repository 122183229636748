import { MaskitoMask, MaskitoOptions, maskitoTransform } from "@maskito/core";

export class CustomMaskitoMask {
	mask: MaskitoMask;
	options: MaskitoOptions;

	constructor(_mask: MaskitoMask, _options?: MaskitoOptions) {
		this.mask = _mask;
		this.options =
			_options ??
			({
				mask: _mask,
			} as MaskitoOptions);
	}

	displayValue(value: string): string {
		return maskitoTransform(value, this.options);
	}
}
