import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PreciseDate } from "../date-util";

@Injectable()
export class JsonDateInterceptor implements HttpInterceptor {
	private static isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const convertedRequest = this.convertRequest(request);

		return next.handle(convertedRequest).pipe(
			map((response: HttpEvent<unknown>) => {
				if (response instanceof HttpResponse) {
					this.convert(response.body);
				}
				return response;
			})
		);
	}

	isIsoDateString(value: unknown): boolean {
		if (value === null || value === undefined) {
			return false;
		}

		if (typeof value !== "string") {
			return false;
		}

		return JsonDateInterceptor.isoDateFormat.test(value);
	}

	convert(body: any): any {
		if (body === null || body === undefined || typeof body !== "object") {
			return body;
		}

		for (const key of Object.keys(body)) {
			const value = body[key];
			if (this.isIsoDateString(value)) {
				body[key] = new PreciseDate(value);
			} else if (typeof value === "object") {
				this.convert(value);
			}
		}
	}

	convertRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
		if (request.body) {
			const convertedBody = this.convertRequestBody(request.body);
			return request.clone({ body: convertedBody });
		}

		return request;
	}

	convertRequestBody(body: any): any {
		if (
			body === null ||
			body === undefined ||
			typeof body !== "object" ||
			body instanceof HttpParams ||
			Object.keys(body).length === 0
		) {
			return body;
		}

		const convertedBody = { ...body };

		for (const key of Object.keys(convertedBody)) {
			const value = convertedBody[key];
			if (value instanceof PreciseDate) {
				convertedBody[key] = value.toISOString();
			}
		}

		return convertedBody;
	}
}
