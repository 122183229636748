export const environment = {
	production: true,
	apiRoot: "##{apiRoot}##",
	authApiRoot: "##{authApiRoot}##",
	appDisplayName: "##{appDisplayName}##",
	appName: "##{appDisplayName}##",
	appVersion: "##{appVersion}##",
	appVersionName: "##{appVersionName}##",
	authority: "##{authority}##",
	clientId: "##{clientId}##",
	clientSecret: "##{clientSecret}##",
	enableAdminSolicitationReading: "##{enableAdminSolicitationReading}##",
	enableIntegrationMode: "##{enableIntegrationMode}##",
	systemPayPaymentUrl: "##{systemPayPaymentUrl}##",
	useRopcFlow: "false",
	googleAnalyticsCode: "##{googleAnalyticsCode}##",
};
