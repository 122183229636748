import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CacheStorageService } from "./cache-storage";
import { PreciseDateDurationPipe, PreciseDatePipe } from "./date-util/pipes";

// Set DayJS globally
import Dayjs from "dayjs";
import "dayjs/locale/fr";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import { StringCapitalizePipe } from "./date-util/pipes/string-capitalize.pipe";
Dayjs.extend(duration);
Dayjs.extend(localeData);
Dayjs.locale("fr");

@NgModule({
	imports: [CommonModule],
	declarations: [PreciseDateDurationPipe, PreciseDatePipe, StringCapitalizePipe],
	exports: [PreciseDateDurationPipe, PreciseDatePipe, StringCapitalizePipe],
	providers: [CacheStorageService],
})
export class UtilsModule {}
