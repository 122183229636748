export enum Colors {
	Error = "error",
	Neutral400 = "neutral-400",
	Neutral800 = "neutral-800",
	Primary = "primary",
	Success = "success",
	White = "white",
	Zinc200 = "zinc-200",
	Zinc600 = "zinc-600",
}
