import { MaskitoOptions } from "@maskito/core";
import {
	maskitoAddOnFocusPlugin,
	maskitoCaretGuard,
	maskitoNumberOptionsGenerator,
	maskitoPrefixPostprocessorGenerator,
	maskitoRemoveOnBlurPlugin,
} from "@maskito/kit";
import { CustomMaskitoMask } from "./custom-maskito-mask";

export class MaskitoMasks {
	private static emptyMask: string[] = [];
	private static ibanMask: string[] = [
		...Array(2).fill(/\w/),
		...Array(2).fill(/\w/),
		" ",
		...Array(4).fill(/\w/),
		" ",
		...Array(4).fill(/\w/),
		" ",
		...Array(4).fill(/\w/),
		" ",
		...Array(4).fill(/\w/),
		" ",
		...Array(4).fill(/\w/),
		" ",
		...Array(3).fill(/\w/),
	];

	private static onlyDigitsMaskOptions = maskitoNumberOptionsGenerator({
		decimalSeparator: ",",
		thousandSeparator: "",
		precision: 2,
	});

	private static phoneNumberMask: string[] = [
		...Array(2).fill(/\d/),
		" ",
		...Array(2).fill(/\d/),
		" ",
		...Array(2).fill(/\d/),
		" ",
		...Array(2).fill(/\d/),
		" ",
		...Array(2).fill(/\d/),
	];

	private static phoneNumberMaskOptions = {
		mask: this.phoneNumberMask,
		postprocessors: [
			// non-removable prefix
			maskitoPrefixPostprocessorGenerator("0"),
		],
		plugins: [
			maskitoAddOnFocusPlugin("0"),
			maskitoRemoveOnBlurPlugin("0"),
			maskitoCaretGuard((value, [from, to]) => [from === to ? "0".length : 0, value.length]),
		],
	} as MaskitoOptions;

	static iban = new CustomMaskitoMask(MaskitoMasks.ibanMask);
	static onlyDigits = new CustomMaskitoMask(MaskitoMasks.emptyMask, MaskitoMasks.onlyDigitsMaskOptions);
	static phoneNumber = new CustomMaskitoMask(MaskitoMasks.phoneNumberMask, MaskitoMasks.phoneNumberMaskOptions);
}
