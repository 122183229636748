export function googleAnalyticsHeadScripts(googleAnalyticsCode) {
	if (!googleAnalyticsCode) {
		console.log("Google Analytics not registered");
	}
	const disableGa = "ga-disable-" + googleAnalyticsCode;
	if (document.cookie.indexOf(disableGa + "=true") > -1) {
		window[disableGa] = true;
	}
	const head = document.getElementsByTagName("head")[0];

	const googleAnalyticsFirstScript = document.createElement("script");
	googleAnalyticsFirstScript.async = true;
	googleAnalyticsFirstScript.src = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

	const googleAnalyticsSecondScript = document.createElement("script");
	googleAnalyticsSecondScript.innerHTML =
		"    window.dataLayer = window.dataLayer || [];\n" +
		"    function gtag(){dataLayer.push(arguments);}\n" +
		"    gtag('js', new Date());\n" +
		"\n" +
		"    gtag('config', '" +
		googleAnalyticsCode +
		"');";

	head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
	head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
}

export function googleAnalytics(url, appName, appVersion, googleAnalyticsCode) {
	// eslint-disable-next-line no-undef
	gtag("config", googleAnalyticsCode, {
		page_path: url,
		app_name: appName,
		app_version: appVersion,
	});
}

export function screenViewEvent(screenName, appName, appVersion) {
	// eslint-disable-next-line no-undef
	gtag("event", "screen_view", {
		app_name: appName,
		app_version: appVersion,
		screen_name: screenName,
	});
}

export function updateVersionEvent(previousVersion, nextVersion) {
	// eslint-disable-next-line no-undef
	gtag("event", "update_version_" + nextVersion, {
		event_category: "update_version",
		event_label: "Update version from " + previousVersion + " to " + nextVersion,
	});
}

export function authSuccessEvent() {
	// eslint-disable-next-line no-undef
	gtag("event", "auth", {
		result: "success",
	});
}

export function contactFormEvent(subject) {
	// eslint-disable-next-line no-undef
	gtag("event", subject, {
		event_category: "contact_form",
		event_label: subject,
	});
}

export function setUserId(userId) {
	// eslint-disable-next-line no-undef
	gtag("set", { user_id: userId });
}
