import { AbstractControl, FormArray, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export class CommonValidators {
	/**
	 * Validate an email address
	 * @returns {ValidatorFn}
	 */
	static email(): ValidatorFn {
		return (control: AbstractControl) => {
			if (!control?.value || !Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")(control)) {
				return null;
			}

			return { email: true };
		};
	}

	/**
	 * Validate an password complexity
	 * @returns {ValidatorFn}
	 */
	static passwordsComplexity(): ValidatorFn {
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){2})(?!.*[€])(?=.*\W).\S{7,15}$/;

		return (control: AbstractControl) => {
			if (!control?.value || !Validators.pattern(passwordRegex)(control)) {
				return null;
			}

			return { passwordComplexity: true };
		};
	}

	/**
	 *
	 * @param controlName
	 * @param matchControlName
	 * @returns
	 */
	static matchingValues(matchControlName: string): ValidatorFn {
		return (control: AbstractControl) => {
			if (!control?.value || control?.value === control?.parent?.value[matchControlName]) {
				return null;
			}
			return { matching: true };
		};
	}

	static minSelectedCheckboxes(min = 1): ValidationErrors | null {
		return (formArray: FormArray) => {
			const totalSelected = formArray.controls
				.map((group) => group.value.checked)
				.reduce((prev, next) => (next ? prev + next : prev), 0);

			return totalSelected >= min ? null : { required: true };
		};
	}

	static phoneNumberFormat(): ValidationErrors | null {
		return (control: AbstractControl) => {
			if (!control?.value) {
				return null;
			}

			// Check if the value is a possible french phone number
			if (isPossiblePhoneNumber(control.value, "FR")) {
				const nationalPhoneNumber = parsePhoneNumber(control.value, "FR");

				// Check if the value is a valid french phone number
				if (nationalPhoneNumber.isValid()) {
					return null;
				}
			}

			return { format: true };
		};
	}
}
