import { AbstractControl, ValidatorFn } from "@angular/forms";
import { PreciseDate } from "../date-util";

export class DatesValidators {
	/**
	 *
	 * @param controlName
	 * @param matchControlName
	 * @returns
	 */
	static greaterThanValidator(matchControlName: string): ValidatorFn {
		return (control: AbstractControl) => {
			if (!matchControlName) return null;

			const matchControl = control.parent?.get(matchControlName);
			if (!matchControl) return null;

			const startDate = matchControl.value;
			const endDate = control.value;
			if (!(startDate instanceof PreciseDate) || !(endDate instanceof PreciseDate)) return null;

			return startDate.insideDate.isBefore(endDate.insideDate) ? null : { greaterThan: true };
		};
	}
}
