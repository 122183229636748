import { Injectable, OnDestroy } from "@angular/core";
import { Observable, ReplaySubject, takeUntil } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class SubscriptionService implements OnDestroy {
	private readonly _destroy$ = new ReplaySubject<boolean>(1);

	readonly takeUntilDestroy = <T>(origin: Observable<T>): Observable<T> => origin.pipe(takeUntil(this._destroy$));

	ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}
}
